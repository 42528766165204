import prependNoneOption from 'src/compass/shared/util/prependNoneOption';

import { useRetrieveEntityList } from './useRetrieveEntityList';

export type EntityListOptionsProps = {
  includeAllOption?: boolean;
  includeNoneOption?: boolean;
};

type Props = EntityListOptionsProps & {
  entity: string;
};

/*  
  #TODO:- 
  In User story 6997, Remove below temporary function "prependAllOption"
*/

export function prependAllOptionWithIdName(options: Array<object>) {
  return [{ id: 'All', name: 'All' }, ...options];
}

export default function useRetrieveEntityListOptions({
  entity,
  includeAllOption = false,
  includeNoneOption = false,
}: Props) {
  const { data, isLoading } = useRetrieveEntityList({ entity });

  let options = data?.result || [];
  if (includeAllOption) options = prependAllOptionWithIdName(options);
  if (includeNoneOption) options = prependNoneOption(options);

  return { options, isLoading };
}
