/* eslint-disable react/jsx-no-useless-fragment */

import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
// sections
import { AuthContext } from 'src/compass/site/context';
import View404 from './components/View404';

export default function Page404() {
  const { loadingPermission } = useContext(AuthContext);
  return !loadingPermission ? (
    <>
      <Helmet>
        <title> 404 Page Not Found!</title>
      </Helmet>
      <View404 />
    </>
  ) : (
    null
  );
}
