import { ChangeEvent, useState } from 'react';
import { useFormContext, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import formatWithConditionalDecimals from 'src/compass/shared/util/formatWithConditionalDecimals';

// ----------------------------------------------------------------------

type Props = Omit<TextFieldProps, 'onChange'> & {
  name: string;
  isCurrencyField?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: any) => void;
};

/* updated RHF-text field component as that component have check which don't allow writing 0
        value={type === 'number' && field.value === 0 ? '' : field.value}
*/

export default function CasRHFTextField({
  name,
  isCurrencyField = false,
  helperText,
  type,
  onChange,
  ...other
}: Props) {
  const { control } = useFormContext();

  const [isFocused, setIsFocused] = useState(false);

  const handleBlur =
    (field: ControllerRenderProps<FieldValues, string>) =>
    (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      field.onBlur();
      if (isCurrencyField && event.target.value) {
        const numberValue = formatWithConditionalDecimals(event.target.value);
        field.onChange(numberValue);
      }
    };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        let displayValue = field.value ?? '';
        if (isCurrencyField && !isFocused)
          displayValue = formatWithConditionalDecimals(field.value);

        return (
          <TextField
            {...field}
            fullWidth
            type={type}
            value={displayValue}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur(field)}
            onChange={(event) => {
              if (type === 'number') {
                field.onChange(event.target.value !== '' ? Number(event.target.value) : null);
              } else {
                field.onChange(event.target.value);
              }
              if (onChange) onChange(event, event.target.value);
            }}
            error={!!error}
            helperText={error ? error?.message : helperText}
            {...other}
          />
        );
      }}
    />
  );
}
