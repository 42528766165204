/* eslint-disable react/jsx-no-useless-fragment */
import './CasTableToolbar.scss';

import { ReactNode } from 'react';
import valueClassname from 'src/compass/shared/util/valueClassname';
import CasPopoverMenu, { IcasPopoverOption } from '../CasPopoverMenu/CasPopoverMenu';
import CasTextField from '../CasTextField/CasTextField';
import CasSelect, { ICasSelect } from '../CasSelect/CasSelect';
import { CasStackRow } from "../CasStack/CasStackRow";
import CasIconButton from "../CasIconButton/CasIconButton";

export type componentProps = Omit<ICasSelect, 'options'> & {
  includeAllOption?: boolean;
  includeNoneOption?: boolean;
}

export interface ICasTableToolbar {
  onFilterChange: (name: string, value: string) => void;
  filters?: any;
  currentFilterValues: any;
  buttons: ReactNode[];
  refreshBtn?: boolean;
  refetch?: any;
  options?: IcasPopoverOption[];
}

const CasTableToolbar = ({
  onFilterChange,
  filters,
  currentFilterValues,
  buttons,
  refreshBtn = false,
  refetch = null,
  options,
}: ICasTableToolbar) => {
  if (refreshBtn && refetch == null)
    throw new Error(
      'CasTableToolbar: RefreshBtn cannot be true without specifying Refetch property'
    );

  const renderFilters = () =>
    filters?.map((filter: any) => {
      const standardProps = {
        className:valueClassname(currentFilterValues[filter.name]),
        name:filter.name,
        key:filter.name,
        label:filter.label,
        onChange:(_:any, value:any) => onFilterChange(filter.name, value),
      }

      const Component = filter?.component;
      switch (filter.type) {
        case 'component':
          return (
            <Component
              {...standardProps}
              includeAllOption={filter?.includeAllOption}
              includeNoneOption={filter?.includeNoneOption}
              value={currentFilterValues[filter.name]}
              sx={{ minWidth: Math.max(85, filter.label.length * 8) }}
            />
          );

        case 'select':
          return (
            <CasSelect
              {...standardProps}
              value={currentFilterValues[filter.name]}
              sx={{minWidth: Math.max(85, filter.label.length * 8)}}
              options={filter.options}
              optionsLabelField={filter.optionsLabelField}
              optionsValueField={filter.optionsValueField}
            />
          );

        default:
          return (
            <CasTextField
              {...standardProps}
              enabledDebounce={filter.enabledDebounce}
            />
          );
      }
    });

  const empty =
    (filters === undefined || filters?.length === 0) &&
    (buttons === undefined || buttons?.length === 0);

  return (
    <CasStackRow
      className={`CasTableToolbar control-no-margin ${empty ? 'empty' : ''}`}
      alignItems="center"
    >
      <CasStackRow alignItems="center" flexGrow={1}>
        {refreshBtn && <CasIconButton icon="raphael:acw" onClick={() => refetch()} />}
        {renderFilters()}
      </CasStackRow>
      <CasStackRow alignItems="center" justifyContent="flex-end">
        {buttons}
      </CasStackRow>
      {options && options?.length > 0 && <CasPopoverMenu options={options} />}
    </CasStackRow>
  );
};

export default CasTableToolbar;
