import { OutlinedInput, Select, MenuItem, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import CasControl from '../CasControl/CasControl';


/*
  CasSelect intentionally does not support multiple props. It is for a single value.
  (If we need multiple=true in the future, we'll implement a separate component wrapper.)
*/

export interface ICasSelect {
  value: string;
  label: string;
  onChange: (event: SelectChangeEvent<any>, value: string) => void;
  placeholder?: string;
  name?: string;
  options: any[];
  optionsLabelField?: string;
  optionsValueField?: string;
  sx?: SxProps<Theme>;
  className?: string;
}

const CasSelect = ({
  className,
  label,
  value,
  onChange,
  placeholder,
  name,
  options,
  optionsLabelField = 'label',
  optionsValueField = 'value',
  sx
}: ICasSelect) => {
  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight === Math.floor(e.target.scrollTop + e.target.clientHeight);

    if (bottom) {
      // add your code here
    }
  };

  return (
    <CasControl label={label}>
      <Select
        className={className}
        value={value ?? ''}
        name={name}
        onChange={(event) => onChange(event, event.target.value)}
        input={<OutlinedInput label={placeholder} />}
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 240 },
            onScroll: handleScroll,
          },
        }}
        sx={sx}
      >
        {options.map((option: any) => (
          <MenuItem key={option[optionsValueField]} value={option[optionsValueField]}>
            {option[optionsLabelField]}
          </MenuItem>
        ))}
      </Select>
    </CasControl>
  );
};

export default CasSelect;
