import { m } from 'framer-motion';
// @mui
import Typography from '@mui/material/Typography';
// assets
import { SeverErrorIllustration } from 'src/assets/illustrations';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
import { CasButton } from 'src/compass/shared/components';
import { paths } from 'src/compass/site/routes';

export default function View500() {
  const handleRetryButtonClick = () => {
    window.location.href = paths.home;
  };

  return (
    <MotionContainer
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'inherit',
      }}
    >
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          There was a problem accessing the server.
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>

      <CasButton size="large" color="inherit" onClick={handleRetryButtonClick}>
        Retry
      </CasButton>
    </MotionContainer>
  );
}
