import { ReactNode } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { Link } from '@mui/material';

// theme
import { bgBlur } from 'src/theme/css';
import CompassLogo from 'src/assets/compass-logo.jpg';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { useSiteProperties } from 'src/compass/application';
import CasLoader from 'src/compass/shared/components/CasLoader/CasLoader';
import { Colors } from 'src/compass/shared/util/Colors';
import { useCasAuthenticated } from 'src/compass/shared/hooks/useUidParam';
//
import { HEADER, NAV } from '../config-layout';
import {
  AccountPopover,
  // SettingsButton,
  // LanguagePopover,
} from '../_common';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const {isAuthenticated} = useCasAuthenticated();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const { data, isLoading, isError } = useSiteProperties();

  const isProductionDatabase = data?.databaseName?.toLowerCase() === 'production';

  let label: string | ReactNode = '';
  if (isLoading) label = <CasLoader />;
  else if (isError) label = 'Error';
  else label = data?.databaseName || '(DatabaseName not specified)';

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && isAuthenticated && (
        <IconButton className="header-menu-button" onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <img src={CompassLogo} alt="Compass Ag Solutions" id="compass-logo" />

      <Link href="tel:+18664334350" id="company-phone">
        866-433-4350
      </Link>

      {!isProductionDatabase && (
        <Chip
          label={label}
          sx={{
            backgroundColor: Colors.Present,
            color: 'black',
            fontWeight: 'bold',
            ml: 2,
            fontSize: 15,
          }}
        />
      )}

      {isAuthenticated && (
        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, sm: 1 }}
        >
          {/* <LanguagePopover /> */}

          {/* <SettingsButton /> */}

          <AccountPopover />
        </Stack>
      )}
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: isAuthenticated ? `calc(100% - ${NAV.W_VERTICAL + 1}px)` : '100%',
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: isAuthenticated ? `calc(100% - ${NAV.W_MINI + 1}px)` : '100%',
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          bgcolor: isProductionDatabase ? 'inherit' : '#F9F6EF',
        }}
      >
        <>
          <div id="toolbar-inside-border" />
          {renderContent}
        </>
      </Toolbar>
    </AppBar>
  );
}
