import { ReactNode } from 'react';
// @mui
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { IconButton } from '@mui/material';
//
import Iconify from 'src/components/iconify';
import { Transition } from 'src/components/custom-dialog';
import { CasStackRow } from '../CasStack/CasStackRow';

export interface ICasBaseDialog extends Omit<DialogProps, 'content' | 'title'> {
  title: string | ReactNode;
  content?: string | ReactNode;
  actions?: ReactNode;
  showCloseIcon?: boolean;
  onClose: VoidFunction;
}

export default function CasBaseDialog({
  open = true,
  title,
  content,
  actions,
  onClose,
  showCloseIcon = false,
  ...other
}: ICasBaseDialog) {
  return (
    <Dialog
      keepMounted
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      fullWidth
      {...other}
    >
      <DialogTitle sx={{ pb: 2 }}>
        <CasStackRow justifyContent={title ? 'space-between' : 'flex-end'}>
          {title}
          {showCloseIcon && (
            <CasStackRow>
              <IconButton size="medium" onClick={onClose}>
                <Iconify width="32" height="32" icon="vaadin:close" />
              </IconButton>
            </CasStackRow>
          )}
        </CasStackRow>
      </DialogTitle>

      <DialogContent color="text.secondary"> {content} </DialogContent>

      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}
