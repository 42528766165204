import { Stack, StackProps } from '@mui/material';

type CasStackRowProps = {
  children: React.ReactNode;
  justifyContent?: StackProps['justifyContent'];
  alignItems?: StackProps['alignItems'];
  flexGrow?: StackProps['flexGrow'];
  gapSize?: 'none' | 'small' | 'large';
  indent?: number;
  className?:StackProps['className'];
};

export function CasStackRow({
  children,
  gapSize = 'small',
  indent = 0,
  ...rest
}: CasStackRowProps) {
  const gapMap = {
    none: 0,
    small: 1,
    large: 5,
  };

  const marginLeft = 2 * indent;

  return (
    <Stack flexDirection="row" gap={gapMap[gapSize]} ml={marginLeft} {...rest}>
      {children}
    </Stack>
  );
}
