import useUidParam from 'src/compass/shared/hooks/useUidParam';
import { PublicLrpQuotesContainer, LrpQuotesPage404 } from './_publicLrpQuotes/components';

export default function PublicLrpQuotesPage() {
  const uid = useUidParam();

  if (!uid || uid <= 0) return <LrpQuotesPage404 />;

  return <PublicLrpQuotesContainer />;
}
