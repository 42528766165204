import { CasBaseDialog, CasLoaderWithBackdrop } from 'src/compass/shared/components';
import { useRetrieveDetailsById } from 'src/compass/shared/hooks/reactQuery/useRetrieveDetailsById';
import SceCreateLogsDetails from './SceCreateLogsDetails';

interface SceDetailsDialogProps {
  onClose: VoidFunction;
  dialogRowId: number;
}

export default function SceCreateLogsDetailsDialog({
  onClose,
  dialogRowId,
}: SceDetailsDialogProps) {
  const queryResult = useRetrieveDetailsById({
    entity: 'Admin/SCECreateLog',
    id: dialogRowId,
  });

  const { data, isLoading, isError } = queryResult;

  if (isLoading) return <CasLoaderWithBackdrop />;

  if (isError) throw new Error('Error retrieving data from the server.');

  return (
    <CasBaseDialog
      open
      maxWidth="lg"
      onClose={onClose}
      title="SCE Create Log Details"
      showCloseIcon
      content={<SceCreateLogsDetails data={data} />}
    />
  );
}
